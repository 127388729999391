import React from "react";

type ActiveLogoProps = {
    width?: string;
};

export const ActiveLogo = (props: ActiveLogoProps) => {
    return (
        <svg
            width={props.width || "800px"}
            height={props.width || "800px"}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#00aa00" }}
        >
            <g id="Page-1" stroke="none" stroke-width="1">
                <g
                    id="Icon-Set"
                    transform="translate(-100.000000, -1139.000000)"
                >
                    <path
                        d="M122.027,1148.07 C121.548,1147.79 120.937,1147.96 120.661,1148.43 L114.266,1159.51 L110.688,1156.21 C110.31,1155.81 109.677,1155.79 109.274,1156.17 C108.871,1156.54 108.85,1157.18 109.228,1157.58 L113.8,1161.8 C114.177,1162.2 114.81,1162.22 115.213,1161.84 C115.335,1161.73 122.393,1149.43 122.393,1149.43 C122.669,1148.96 122.505,1148.34 122.027,1148.07 L122.027,1148.07 Z M116,1169 C108.268,1169 102,1162.73 102,1155 C102,1147.27 108.268,1141 116,1141 C123.732,1141 130,1147.27 130,1155 C130,1162.73 123.732,1169 116,1169 L116,1169 Z M116,1139 C107.164,1139 100,1146.16 100,1155 C100,1163.84 107.164,1171 116,1171 C124.836,1171 132,1163.84 132,1155 C132,1146.16 124.836,1139 116,1139 L116,1139 Z"
                        id="checkmark-circle"
                    ></path>
                </g>
            </g>
        </svg>
    );
};
